import { FC } from "react";
import styles from "./Load.module.scss";
import Confirm from "../../assets/kisspng-scalable-vector-graphics-check-mark-clip-art-green-tick-transparent-png-5a756eee0172b8.237112151517645550006.png";
import { Spin } from "antd";

const Load: FC = () => {
  return (
    <div className={styles.emailContainer}>
      <div className={styles.loading}>
        <Spin size="large" />
      </div>
    </div>
  );
};

export default Load;
