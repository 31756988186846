import axios from "axios";
import endpoints from "./endpoints";

interface IPostEmail {
  email: string;
}

export const postEmail = (store: IPostEmail) => {
  const url = endpoints.API.delete.postEmail;
  return axios.post(url, store);
};
