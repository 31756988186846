import { FC } from "react";
import Container from "../Components/Container/Container";
import styles from "./Home.module.scss";

const Home: FC = () => {
  return (
    <div className={styles.home}>
      <Container />
    </div>
  );
};

export default Home;
