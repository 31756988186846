import { useMutation, useQueryClient } from "react-query";
import { postEmail } from "../axiosCalls";

export enum EStoreKey {
  POST_EMAIL = "post-email",
  DELETE_EMAIL = "delete-email",
}

export const usePostEmail = () => {
  const queryClient = useQueryClient();

  return useMutation(postEmail, {
    onSuccess: () => {
      queryClient.invalidateQueries(EStoreKey.POST_EMAIL);
    },
  });
};
