import { FC } from "react";
import styles from "./EmailSent.module.scss";
import Email from "../../assets/email-envelope-outline-icon-on-transparent-background-free-png.webp";

const EmailSent: FC = () => {
  return (
    <div className={styles.emailContainer}>
      <h2 className={styles.emailHeader}>Check your email!</h2>
      <section className={styles.imageBox}>
        <img className={styles.imageBox_img} src={Email} alt="Email Icon" />
      </section>
      <p className={styles.emailText}>
        An email has been sent your address, click the link inside to complete
        the process!
      </p>
    </div>
  );
};

export default EmailSent;
