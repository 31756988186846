import { FC } from "react";
import styles from "./UhOh.module.scss";
import Nope from "../../assets/kisspng-x-mark-check-mark-desktop-wallpaper-clip-art-x-mark-5ac194d4bec907.7595189015226359887815.png";

const UhOh: FC = () => {
  return (
    <div className={styles.emailContainer}>
      <h2 className={styles.emailHeader}>Oops!</h2>
      <section className={styles.imageBox}>
        <img className={styles.imageBox_img} src={Nope} alt="Nope Icon" />
      </section>
      <p className={styles.emailText}>
        Something went wrong! Please check your email and try again!
      </p>
    </div>
  );
};

export default UhOh;
