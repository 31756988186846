import { FC } from "react";
import styles from "./Confirmed.module.scss";
import Confirm from "../../assets/kisspng-scalable-vector-graphics-check-mark-clip-art-green-tick-transparent-png-5a756eee0172b8.237112151517645550006.png";

const Confirmed: FC = () => {
  return (
    <div className={styles.emailContainer}>
      <h2 className={styles.emailHeader}>Account Deleted!</h2>
      <section className={styles.imageBox}>
        <img className={styles.imageBox_img} src={Confirm} alt="Confim Icon" />
      </section>
      <p className={styles.emailText}>
        You account has now been deleted. We hope to see you again soon!
      </p>
    </div>
  );
};

export default Confirmed;
