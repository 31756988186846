import { FC, useState } from "react";
import "./App.scss";
import Home from "./pages/Home";
import { QueryClient, QueryClientProvider } from "react-query";
import { MyGlobalContext } from "./config/store";
import { BrowserRouter } from "react-router-dom";

const queryClient = new QueryClient();

const App: FC = () => {
  const [page, setPage] = useState<string>("home");
  return (
    <BrowserRouter>
      <div className="App">
        <QueryClientProvider client={queryClient}>
          <MyGlobalContext.Provider
            value={{
              page,
              setPage,
            }}>
            <Home />
          </MyGlobalContext.Provider>
        </QueryClientProvider>
      </div>
    </BrowserRouter>
  );
};

export default App;
