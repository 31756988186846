import { FC, useEffect, useState } from "react";
import styles from "./EmailForm.module.scss";
import { Button, Input } from "antd";
import { usePostEmail } from "../../config/hooks/hooks";
import { useGlobalContext } from "../../config/store";

const EmailForm: FC = () => {
  const { setPage } = useGlobalContext();
  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [hasAtSymbol, setHasAtSymbol] = useState(false);

  const submitEmail = usePostEmail();

  useEffect(() => {
    if (email === "") {
      setError(false);
    }
  }, [email]);

  const onSubmit = async () => {
    setLoading(true);
    const store = {
      email: email,
    };

    submitEmail.mutate(store, {
      onSuccess: (result) => {
        console.log(result);
        setLoading(false);
        setPage("EmailSent");
      },
      onError: () => {
        setLoading(false);
        setError(true);
      },
    });
  };

  const handleInputChange = (e: any) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setHasAtSymbol(newEmail.includes("@"));
  };

  return (
    <div className={styles.emailContainer}>
      <h2 className={styles.emailHeader}>We're sorry to see you go!</h2>
      <p className={styles.emailText}>
        Deleting your account is never an easy decision, but we understand that
        sometimes it's necessary. Just enter your email address below, and we'll
        take care of the rest.
      </p>

      <Input
        placeholder="Enter your account email address here.."
        onChange={handleInputChange}
        style={{ width: "90%", marginTop: "10px" }}
        type="email"
      />

      {error && (
        <p className={styles.error}>
          Oops! Something went wrong, please check your email and try again.
        </p>
      )}

      <Button
        loading={loading}
        disabled={hasAtSymbol === false}
        onClick={onSubmit}>
        Submit Request
      </Button>
    </div>
  );
};

export default EmailForm;
