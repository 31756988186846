import { createContext, useContext } from "react";

export type GlobalContent = {
  page: string;
  setPage: (p: string) => void;
};

export const MyGlobalContext = createContext<GlobalContent>({
  page: "home",
  setPage: () => {},
});

export const useGlobalContext = () => useContext(MyGlobalContext);
